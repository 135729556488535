<template>
  <div>
    <div v-if="indexStore.debugAds" class="w-full h-full bg-red text-white p-8">
      {{ adUnitName }}
    </div>
    <div
      v-else-if="fullWidth"
      class="w-full"
      :style="{
        minHeight: minHeight ? minHeight + 'px' : '',
      }"
    >
      <div class="flex justify-center" :id="tagId"></div>
    </div>
    <div
      v-else
      :id="tagId"
      :style="{
        minHeight: minHeight ? minHeight + 'px' : '',
      }"
    ></div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  id: string
  fullWidth?: boolean
  minHeight?: number
  tempFullWith?: boolean
}>()

declare const window: {
  lwhb?: {
    cmd?: unknown[]
    loadAd: (options: { tagId: string; adUnitName?: string }) => void
  }
} & Window

const siteConfig = useSiteConfig()
const indexStore = useIndexStore()
const tagId = useId()

const cookieConsent = useCookieConsent()

const { onLoaded } = useScript(
  {
    src: 'https://lwadm.com/lw/pbjs?pid=4498821b-f27a-45b4-b584-78b5d8978b84',
    tagPosition: 'head',
  },
  {
    key: 'livewrapped',
    trigger: useScriptTriggerConsent({ consent: cookieConsent.marketing }),
  }
)

const adUnitName = computed(() => {
  const placementId = props.id.replace('altinget-', '').replace('mm-', '')
  // TODO: Switch to use siteConfig.ads?.placementPrefix when migrating whole site to new ad system
  return `${siteConfig.domainfull}_${placementId}`
})

onLoaded(() => {
  window.lwhb?.cmd?.push(() => {
    window.lwhb?.loadAd({
      tagId,
      adUnitName: adUnitName.value,
    })
  })
})
</script>
